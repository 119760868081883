import React from 'react'

import Mail from '../Mail/Mail'

const Imprint = (): JSX.Element => {
  return (
    <section className="Imprint">
      <h1>Imprint</h1>
      <p>DISCLOSURES ACCORDING TO § 5 TM</p>
      <h2>galaniprojects GmbH</h2>
      <p>
        Keithstraße 2-4
        <br />
        10787 Berlin
        <br />
        <br />
        Register entry
        <br />
        Entry in the commercial register
        <br />
        Register court: Charlottenburg Local Court
        <br />
        Registration number: HRB 175723 B
        <br />
        <br />
        REPRESENTED BY
        <br />
        Mrs. Anastasia Galani
        <br />
        <br />
        RESPONSIBLE FOR THE CONTENT
        <br />
        Anastasia Galani
        <br />
        Keithstraße 2-4
        <br />
        10787 Berlin
        <br />
        <br />
        Phone: +49 (0) 30 2123 2827
        <br />
        Fax: +49 (0) 30 2191 3767
        <br />
        Email: <Mail mail="info@galaniprojects.de" />
      </p>
    </section>
  )
}

export default Imprint
